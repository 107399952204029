export default [
  {
    title: 'AnaSayfa',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Siparişler',
    route: 'order-order',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Ürünler',
    route: 'product-product',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Ty Ürünler',
    route: 'trendyol-product',
    icon: 'ShoppingCartIcon',
  },
]
